import React, { useEffect, useRef, useState } from "react";
import { DayPilot, DayPilotScheduler } from "daypilot-pro-react";
import { Container } from "aurelia-dependency-injection";
import { I18N } from "aurelia-i18n";
import { CalendarFormDialog } from './calendar-form-dialog';
import { DialogService } from 'aurelia-dialog';
import "./css/style.css";

export const CalendarDisplay = ({
                                    resources,
                                    events,
                                    columns,
                                    compactView,
                                    fromDate,
                                    createFormId,
                                    referenceModelId,
                                    detailPageId,
                                    days,
                                    locale
                                }) => {

    const createFormIdRef = useRef(createFormId);
    const referenceModelIdRef = useRef(referenceModelId);
    const detailPageIdRef = useRef(detailPageId);

    useEffect(() => {
        createFormIdRef.current = createFormId;
        referenceModelIdRef.current = referenceModelId;
        detailPageIdRef.current = detailPageId;
    }, [createFormId, referenceModelId, detailPageId]);

    const i18n = Container.instance.get(I18N);
    const dialogService = Container.instance.get(DialogService);

    let timeline = [];
    let scale = 'Day';

    if (compactView) {
        for (let column of columns) {
            const day = {};
            day.start = new DayPilot.Date(column.fromDate);
            day.end = new DayPilot.Date(column.toDate);

            const duration = new DayPilot.Duration(day.start, day.end);

            if (duration.totalDays() <= 2) {
                day.width = 30 * duration.totalDays();
            } else {
                day.width = 90;
            }

            timeline.push(day);
        }
    }

    const schedulerRef = useRef();

    const [config, setConfig] = useState({
        locale: locale,
        startDate: fromDate,
        days: days,
        scale: scale,
        timeline: timeline,
        timeHeaders: [
            { groupBy: "Month" },
            { groupBy: "Cell" }
        ],
        onBeforeTimeHeaderRender: function (args) {
            if (args.header.level === 1) {
                const duration = new DayPilot.Duration(args.header.start, args.header.end);

                if (duration.totalDays() > 1) {
                    args.header.html = args.header.start.getDay() + ' - ' + (args.header.end.getDay() - 1);
                }
            }
        },
        cellWidthSpec: 'Auto',
        cellWidthMin: 30,
        durationBarVisible: false,
        eventMoveHandling: "Update",
        eventResizeHandling: "Disabled",
        cellBubble: new DayPilot.Bubble({
            position: "Right",
            onBeforeDomAdd: args => {
                const dp = getScheduler();

                const resource = dp.rows.find(args.source.resource);
                const limits = resource?.data?.limits;

                if (!limits) {
                    return;
                }

                const limit = limits[args.source.start.toString('yyyy-MM-dd')] ?? null;

                if (!limit || !limit.bubbleHtml) {
                    return;
                }

                args.html = limit.bubbleHtml;
            },
        }),
        onEventClicked: (args) => {
            if (args?.e?.data?.link) {
                window.open(args?.e?.data?.link, '_blank');
            }
        },
        onTimeRangeSelected: async args => {
            const dialog = dialogService.open({
                viewModel: CalendarFormDialog,
                model: {
                    createFormId: createFormIdRef.current,
                    referenceModelIdRef: referenceModelIdRef.current,
                    detailPageIdRef: detailPageIdRef.current,
                    data: args
                }
            }).whenClosed(data => {
                const addEvent = data?.output?.data;
                const dp = getScheduler();
                if (!addEvent?.availableType) {
                    dp.clearSelection();
                    return;
                }
                const color = addEvent?.availableType === 'available' ? '#6FBF6C' : '#EE5151';

                let availableTypeValue;
                if (addEvent?.availableType === 'available') {
                    availableTypeValue = i18n.tr('availability-scheduler.available');
                } else {
                    switch (addEvent?.reasonType) {
                        case 'planned':
                            availableTypeValue = i18n.tr('availability-scheduler.planned');
                            break;
                        case 'sick':
                            availableTypeValue = i18n.tr('availability-scheduler.sick');
                            break;
                        case 'vacation':
                            availableTypeValue = i18n.tr('availability-scheduler.vacation');
                            break;
                    }
                }
                const bubbleHtmlTemplate = `
            <p>
                <b>${i18n.tr('sio.field.dates')}: </b> ${args.start.toString('d.MM.yyyy')} - ${args.end.toString('d.MM.yyyy')}<br>
                ${addEvent?.reasonType === 'planned' ?
                    `<b>${i18n.tr('availability-scheduler.reason')}: </b> ${i18n.tr('availability-scheduler.planned')}<br>` :
                    addEvent?.reasonType === 'sick' ?
                        `<b>${i18n.tr('availability-scheduler.reason')}: </b> ${i18n.tr('availability-scheduler.sick')}<br>` :
                        addEvent?.reasonType === 'vacation' ?
                            `<b>${i18n.tr('availability-scheduler.reason')}: </b> ${i18n.tr('availability-scheduler.vacation')}<br>` :
                            ''}
                ${addEvent?.reason ? `<b>${i18n.tr('availability-scheduler.note')}: </b> ${addEvent.reason.substring(0, 220)}<br>` : ''}
            </p>`;

                const href = 'view/' + detailPageIdRef.current + '?id=' + addEvent?.id;

                const newEvent = {
                    availableType: addEvent?.availableType,
                    blockedGuide: false,
                    guideLink: addEvent?.guideLink,
                    manually: true,
                    text: availableTypeValue,
                    reasonType: addEvent?.reasonType,
                    start: args.start,
                    end: args.end,
                    id: addEvent?.id,
                    version: 0,
                    resource: args.resource,
                    backColor: color,
                    bubbleHtml: bubbleHtmlTemplate,
                    link: href,
                    moveDisabled: addEvent?.moveDisabled
                };

                dp.events.add(newEvent);
                dp.clearSelection();

                if (args.resource !== addEvent?.guideLink.id) {
                    window.location.reload();
                }
            });
        },
    });

    const getScheduler = () => schedulerRef.current.control;

    useEffect(() => {
        getScheduler().update({
            resources,
            events
        });
    }, [resources, events]);

    return (
        <div>
            <DayPilotScheduler
                {...config}
                ref={schedulerRef}
            />
        </div>
    );

};

export default CalendarDisplay;
